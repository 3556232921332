import {
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition,
} from '@headlessui/react'
import React, { Fragment } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import useUser, { isDomainOwner, isWsAdmin, isWsAssistant } from 'shared/hooks/use-user'
import ChevronUpIcon from 'shared/icons/chevron-up-icon'
import AddTopic from 'modules/community/components/add-topic'
import { useCommunityWithTopics } from 'modules/community/hooks/use-community-with-topics'

const getOptionClassName = ({ active }: { active: boolean }) =>
  `relative cursor-default select-none py-2 px-5 ${
    active ? 'bg-amber-100 text-blue' : 'text-gray-900'
  }`

function MobileNav() {
  const { t } = useTranslation()
  const { user } = useUser()
  const { data: community } = useCommunityWithTopics()
  const router = useRouter()
  const { topicPath, communityPath } = router.query

  if (!community || !user) {
    return (
      <div className="flex animate-pulse justify-between px-5 py-2">
        <div className="h-6 w-10/12 rounded-lg bg-gray" />
        <div className="h-6 w-1/12 rounded-lg bg-gray" />
      </div>
    )
  }
  const selectedTopic = community.topics.find(topic => topic.path === topicPath)
  const isSettingsPage = router.pathname.includes('settings')
  const isMembersPage = router.pathname.includes('members')

  function switchTopic(topicPath: string) {
    router.push(`/community/${communityPath}/${topicPath}`)
  }

  let currentMenuItemName
  let currentMenuItemValue
  if (selectedTopic) {
    currentMenuItemName = `#${selectedTopic.name}`
    currentMenuItemValue = selectedTopic.name
  } else if (isSettingsPage) {
    currentMenuItemName = t(`mobile_nav.settings`)
    currentMenuItemValue = 'settings'
  } else if (isMembersPage) {
    currentMenuItemName = t(`mobile_nav.members`)
    currentMenuItemValue = 'members'
  } else {
    currentMenuItemName = t(`mobile_nav.home`)
    currentMenuItemValue = ''
  }

  return (
    <div className="relative z-10 block border-b-[1px] border-gray/40 lg:hidden">
      <Listbox value={currentMenuItemValue} onChange={switchTopic}>
        <div className="relative mt-1">
          <div className={'flex items-center px-5 py-2'}>
            <ListboxButton className="focus-visible:border-indigo-500 focus-visible:ring-offset-orange-300 relative w-full cursor-default rounded-lg text-left focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm">
              <span className="block truncate">{currentMenuItemName}</span>
              <span className="pointer-events-none absolute inset-y-0 right-2 flex items-center pr-2">
                <ChevronUpIcon />
              </span>
            </ListboxButton>

            {(isDomainOwner(user) || isWsAdmin(user) || isWsAssistant(user)) && (
              <div className={'flex items-center gap-2'}>
                <span className="block truncate">{t('settings.form.add_topic.label')}</span>
                <AddTopic />
              </div>
            )}
          </div>

          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <ListboxOptions className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-lightblue py-1 text-base shadow-lg focus:outline-none sm:text-sm">
              <ListboxOption key="home" value={''} className={getOptionClassName}>
                {t(`mobile_nav.home`)}
              </ListboxOption>

              {community.topics.map(topic => (
                <ListboxOption key={topic.id} className={getOptionClassName} value={topic.path}>
                  {({ selected }) => (
                    <>
                      <span
                        className={`block truncate pl-4 ${
                          selected ? 'font-medium' : 'font-normal'
                        }`}
                      >
                        #{topic.name}
                      </span>
                    </>
                  )}
                </ListboxOption>
              ))}
              <ListboxOption key="members" className={getOptionClassName} value="members">
                {t(`mobile_nav.members`)}
              </ListboxOption>
              {(isDomainOwner(user) || isWsAdmin(user)) && (
                <>
                  <ListboxOption key="settings" className={getOptionClassName} value="settings">
                    {t(`mobile_nav.settings`)}
                  </ListboxOption>
                  {!community.autoPostsApproval && (
                    <ListboxOption
                      className={getOptionClassName}
                      value="pending-posts"
                      key="pending-posts"
                    >
                      {t('sidebar.nav.main.pending_posts')}
                    </ListboxOption>
                  )}
                </>
              )}
            </ListboxOptions>
          </Transition>
        </div>
      </Listbox>
    </div>
  )
}

export default MobileNav
