import { useTranslation } from 'next-i18next'
import EllipseIcon from 'shared/icons/ellipse-icon'
import { UserInfoPopover } from 'modules/community/components/user-info-popover/user-info-popover'
import { getReadableMemberWorkspaceRole } from 'modules/community/enums/member-enum'
import { PostAuthorInterface } from 'modules/community/types/post-interface'

interface AuthorInfoProps {
  author?: PostAuthorInterface
  userId: number
}

export const PostAuthorInfo = ({ author, userId }: AuthorInfoProps) => {
  const { t } = useTranslation()

  return (
    <p className="inline-flex h-[40px] items-center gap-2.5 text-bluegray">
      <UserInfoPopover userId={userId}>
        <img
          src={author?.avatarUrl || '/default_post_author_avatar.png'}
          alt={author?.displayName}
          className="h-10 w-10 rounded-full"
          width={40}
          height={40}
        />
      </UserInfoPopover>
      <div className="flex flex-col items-center">
        <span className="max-w-[250px] overflow-hidden text-ellipsis whitespace-nowrap text-darkblue md:max-w-sm">
          {author?.displayName}
        </span>
        {author?.workspaceRole && (
          <span className="font-small self-start rounded border border-transparent bg-[#00a0ff]/[0.07] px-[5px] text-[10px] leading-5 text-[#0279e8]/[1]">
            {t(getReadableMemberWorkspaceRole(author.workspaceRole))}
          </span>
        )}
      </div>
      <EllipseIcon />
    </p>
  )
}
