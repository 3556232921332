import useUser, {
  isAuthorizedUser,
  isWorkspaceOwner,
  isWsAdmin,
  isWsAssistant,
} from 'shared/hooks/use-user'
import { PostStatusEnum } from '../enums/post-status-enum'
import { PostInterface } from '../types/post-interface'
import useIsUserMember from './use-is-user-member'

export const usePostPermissions = (post: PostInterface) => {
  const { user, isOwner: isDomainOwner } = useUser()
  const { member } = useIsUserMember()

  const isPostAuthor = isAuthorizedUser(user) && post.userId === member?.userId
  const isAdminOrAssistant = isWsAdmin(user) || isWsAssistant(user)

  const isPostManageable =
    isPostAuthor ||
    ((isDomainOwner || isAdminOrAssistant) &&
      post.status === PostStatusEnum.Approved &&
      !post.author?.workspaceRole)

  const isPostUpdatable = isPostAuthor

  const isPostDeletable =
    isPostAuthor ||
    ((isDomainOwner || isWorkspaceOwner(user) || isAdminOrAssistant) && !post.author?.workspaceRole)

  const isPostAuthorBannable =
    isAuthorizedUser(user) &&
    !isPostAuthor &&
    (isDomainOwner ||
      ((isWorkspaceOwner(user) || isAdminOrAssistant) && !post.author?.workspaceRole))

  const isPostDeclinableAndAuthorBannable =
    isAuthorizedUser(user) &&
    !isPostAuthor &&
    (isDomainOwner ||
      ((isWorkspaceOwner(user) || isAdminOrAssistant) && !post.author?.workspaceRole))

  return {
    isPostManageable,
    isPostUpdatable,
    isPostDeletable,
    isPostAuthorBannable,
    isPostDeclinableAndAuthorBannable,
  }
}
