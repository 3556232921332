import { Float } from '@headlessui-float/react'
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOptions, Label } from '@headlessui/react'
import { ElementType, ReactNode, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'next-i18next'
import { twMerge } from 'tailwind-merge'
import ArrowDown from 'shared/icons/arrow-down-icon'
import CloseIcon from 'shared/icons/close-icon'
import BaseList from './base-list'
import {
  FieldErrorAndDescription,
  FieldErrorAndDescriptionProps,
} from './form/field-error-and-description'
import { FieldLabel, FieldLabelProps } from './form/field-label'
import { Loader } from './loader'

export type ExtendedDataType = {
  id: string | number | null | undefined
  caption: string
  customCaption?: JSX.Element | string
  group?: string
}

export interface DataInterface<T extends ExtendedDataType> {
  id: T['id']
  caption: string
}

export interface GroupDataInterface {
  id: string
  label: string
}

export type FormSelectProps<T extends ExtendedDataType> = FieldErrorAndDescriptionProps &
  FieldLabelProps & {
    htmlFor?: string
    data: T[] | undefined
    staticData?: T[]
    onChange: (data: DataInterface<T>['id']) => void
    isPreFetching?: boolean
    value?: DataInterface<T>['id']
    placeholder?: string
    className?: string
    wrapperClassName?: string
    disabled?: boolean
    withoutCloseIcon?: boolean
    onOpen?: () => void
    small?: boolean
    createData?: {
      setCreateData: (data: { name: string }) => void
      getCustomCaption: (data: string) => string
    }
    isCreating?: boolean
    dataInputAttribute?: string
    floatWrapper?: ElementType
    getOptionDataAttribute?: (value?: string) => void
    groups?: GroupDataInterface[]
    optionIconRenderer?: (data?: T) => ReactNode | undefined
  }

const defaultPlaceholder = 'global.select'

const CREATE_DATA_OPTION_ID = null

export default function EditableFormSelect<T extends ExtendedDataType>({
  label,
  data,
  staticData,
  htmlFor,
  onChange,
  labelClassName,
  value,
  isPreFetching,
  placeholder,
  error,
  required,
  description,
  className,
  wrapperClassName,
  disabled,
  withoutCloseIcon,
  onOpen,
  small,
  createData,
  isCreating,
  dataInputAttribute,
  floatWrapper,
  getOptionDataAttribute,
  groups,
  optionIconRenderer,
}: FormSelectProps<T>) {
  const { t } = useTranslation()

  const [opened, setOpened] = useState(false)

  useEffect(() => {
    if (opened && onOpen) {
      onOpen()
      setOpened(false)
    }
  }, [onOpen, opened])

  const dropdownRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const [buttonWidth, setButtonWidth] = useState(400)

  const [selectedData, setSelectedData] = useState<T | null>(
    data
      ? data.find(el => el.id == value) || null
      : staticData
        ? staticData.find(el => el.id == value) || null
        : null,
  )

  useEffect(() => {
    if (!isCreating) {
      setSelectedData(
        data
          ? data.find(el => el.id == value) || null
          : staticData
            ? staticData.find(el => el.id == value) || null
            : null,
      )
    }
  }, [data, value, staticData])

  const [query, setQuery] = useState('')

  const [filteredData, setFilteredData] = useState(data)

  useEffect(() => {
    const filteredData =
      query === ''
        ? data
        : (data?.filter((item: T) =>
            item.caption
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(query.toLowerCase().replace(/\s+/g, '')),
          ) ?? [])
    if (createData && query !== '') {
      filteredData?.unshift({
        id: CREATE_DATA_OPTION_ID,
        caption: query,
        customCaption: createData.getCustomCaption(query),
      } as T)
    }
    setFilteredData(() => filteredData)
  }, [data, query])

  const disabledNoDataWithValue = !staticData && value && !data

  return (
    <div className={twMerge(`relative flex flex-col gap-1`, wrapperClassName)}>
      <Combobox
        immediate
        value={selectedData}
        onChange={data => {
          if (createData && data && data.id === CREATE_DATA_OPTION_ID) {
            createData.setCreateData({ name: data.caption })
            setSelectedData({ id: data.id, caption: data.caption } as T)
          } else data && onChange(data.id)
        }}
        disabled={disabled || isPreFetching || disabledNoDataWithValue}
      >
        {({ open }) => {
          return (
            <Float
              offset={4}
              portal
              onUpdate={() => {
                if (dropdownRef.current && inputRef.current) {
                  const buttonWidth = inputRef.current.getBoundingClientRect().width
                  setButtonWidth(buttonWidth)
                  dropdownRef.current.style.width = `${buttonWidth}px`
                }
              }}
              flip
              as={'div'}
              onHide={() => setQuery('')}
              {...(floatWrapper ? { floatingAs: floatWrapper } : {})}
            >
              <div className="flex flex-col gap-1">
                <FieldLabel
                  wrapperAs={Label}
                  wrapperProps={{ htmlFor: htmlFor }}
                  label={label}
                  required={required}
                  labelClassName={twMerge(`text-sm font-medium`, labelClassName)}
                />
                <div className="relative">
                  <ComboboxInput
                    autoComplete="off"
                    className={twMerge(
                      'relative flex w-full items-center justify-between truncate rounded-lg py-2.5 pl-4 pr-[59px]',
                      'main-transition-colors cursor-default border text-left text-sm text-darkblue',
                      'focus:outline-none focus-visible:border-blue',
                      !small && 'lg:min-w-[200px]',
                      disabled || disabledNoDataWithValue
                        ? isPreFetching
                          ? 'bg-gray-600/40'
                          : 'bg-gray-200/40'
                        : 'bg-white',
                      open ? 'border-blue' : error ? 'border-danger' : 'border-gray',
                      isPreFetching
                        ? 'animate-pulse bg-gray-600/40 text-transparent placeholder:text-transparent'
                        : 'text-darkblue placeholder:text-gray-300/70',
                      isCreating && 'animate-pulse bg-gray-600/40',
                      className,
                    )}
                    ref={inputRef}
                    onChange={event => {
                      setOpened(true)
                      setQuery(event.target.value)
                    }}
                    onFocus={() => {
                      setOpened(true)
                    }}
                    displayValue={(item: T | null) => (item ? item.caption : '')}
                    placeholder={placeholder || t(defaultPlaceholder)}
                    {...(dataInputAttribute ? { ['data-test-element']: dataInputAttribute } : {})}
                  />
                  <ComboboxButton
                    as={'div'}
                    className="w-full cursor-default overflow-hidden rounded-lg text-left"
                  >
                    {value !== undefined && !withoutCloseIcon && (
                      <div
                        onClick={e => {
                          if (disabled || disabledNoDataWithValue || isPreFetching) return
                          e.stopPropagation()
                          onChange(undefined!)
                        }}
                        className={`absolute inset-y-0 right-0 flex items-center pr-10`}
                      >
                        <CloseIcon
                          className={twMerge(
                            disabled || disabledNoDataWithValue || isPreFetching
                              ? 'cursor-default fill-transparent'
                              : 'cursor-pointer fill-darkblue',
                          )}
                        />
                      </div>
                    )}
                    <div className="absolute inset-y-0 right-0 flex items-center pr-4">
                      <ArrowDown
                        className={twMerge(
                          'transition-transform duration-300',
                          disabled || disabledNoDataWithValue || isPreFetching
                            ? 'cursor-default stroke-transparent'
                            : 'cursor-pointer stroke-darkblue',
                          open && 'rotate-180',
                        )}
                        aria-hidden="true"
                      />
                    </div>
                  </ComboboxButton>
                </div>
              </div>
              <ComboboxOptions className={'relative focus-visible:outline-none'}>
                <div ref={dropdownRef} className={'rounded-lg bg-white'}>
                  {data === undefined ? (
                    <div className="flex justify-center rounded-md border border-gray/30 bg-white px-4 py-1">
                      <Loader className="scale-75" />
                    </div>
                  ) : (
                    <BaseList
                      data={filteredData}
                      value={value}
                      getOptionDataAttribute={getOptionDataAttribute}
                      groups={groups}
                      optionIconRenderer={optionIconRenderer}
                      as={Combobox.Option}
                    />
                  )}
                </div>
              </ComboboxOptions>
            </Float>
          )
        }}
      </Combobox>
      <FieldErrorAndDescription description={description} error={error} />
    </div>
  )
}
