import React, { useState } from 'react'
import { useTranslation } from 'next-i18next'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { twMerge } from 'tailwind-merge'
import { LocaleEnum } from 'shared/enums/locale-enum'
import useWindowSize from 'shared/hooks/use-window-size'
import { handleWithoutPropagating } from 'shared/utils/handle-without-propagating'
import { PostAttachmentsProvider } from 'modules/attachments/components/context'
import AttachmentPreviewItem from 'modules/attachments/components/preview/preview-item'
import LikeButton from 'modules/community/components/like/like-button'
import PostModalEdit from 'modules/community/components/post-modal-edit'
import { PostStatusEnum } from 'modules/community/enums/post-status-enum'
import { usePostPermissions } from 'modules/community/hooks/use-post-permissions'
import { PostInterface } from 'modules/community/types/post-interface'
import { getPrimaryAttachment } from 'modules/community/utils/attachments'
import { timeSince } from 'modules/post/utils/time-utils'
import { TextWithMentions } from '../editor/utils/deserealizeMentionToHTML'
import { PostAuthorInfo } from './author-info'
import PostCommentsInfo from './comments-info'
import PostModal from './post-modal'

type PostAnnounceProps = {
  post: PostInterface
  deletePost: () => void
  deletePostAndBanUser: (removeRecentActivitiesChecked: boolean) => void
  handleLikeClick?: () => void
  dashboardLocale?: LocaleEnum
}

const MAX_CONTENT_LENGTH_MOBILE = 125
const POST_TITLE_LENGTH_LIMIT = 40

function PostAnnounce({
  post,
  deletePost,
  handleLikeClick,
  dashboardLocale,
  deletePostAndBanUser,
}: PostAnnounceProps) {
  const { t } = useTranslation()
  const router = useRouter()
  const { width } = useWindowSize()

  const { communityPath, topicPath: topicPathParam } = router.query
  const communityPathWithSlug = `/community/${communityPath}`
  const topicPath = `/community/${communityPath}/${post.topic.path}`
  const postDetailsPath = `${topicPath}/${post.path}`
  const { isPostManageable } = usePostPermissions(post)

  const [opened, setOpened] = useState(false)

  const handleModalOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const target = e.target as HTMLElement
    const isLink = target.tagName.toLowerCase() === 'a'

    if (!isLink) {
      if (window) {
        window.history.replaceState(null, '', postDetailsPath)
      }
      setOpened(true)
    }
  }

  const handleModalClose = () => {
    if (window) {
      window.history.replaceState(null, '', topicPathParam ? topicPath : communityPathWithSlug)
    }
    setOpened(false)
  }

  const commentsCount = post.postCommentsInfo?.ok
    ? (post.postCommentsInfo.value?.numberOfComments ?? 0)
    : 0

  const primaryAttachment = getPrimaryAttachment(post.attachments)
  const excessivePostTitleLength = post.title.length - POST_TITLE_LENGTH_LIMIT

  return (
    <PostAttachmentsProvider attachments={post.attachments}>
      <div
        onClick={handleModalOpen}
        className="mb-8 w-full cursor-pointer rounded-lg border border-[#ECEEF1] bg-white px-5 py-[25px] font-avertaPE shadow-[0px_4px_20px_0px_#F2F4F5] transition-shadow hover:shadow-[0px_4px_25px_0px_rgb(255,255,255)]"
      >
        <div className="mb-2 flex justify-between sm:mb-5">
          <div className="flex flex-wrap items-center gap-x-2.5">
            <PostAuthorInfo author={post.author} userId={post.userId} />
            <div className="bluegray inline lg:block">
              <span className="text-lightgray-100">
                {timeSince(new Date(post.createdAt), dashboardLocale)}
              </span>{' '}
              <span className="text-lightgray-100">{t('community.pages_on')}</span>{' '}
              <Link className="font-semibold text-[#142D63]" href={topicPath} shallow>
                #{post.topic.name}
              </Link>
            </div>
          </div>
          {isPostManageable && (
            <PostModalEdit
              post={post}
              deletePost={deletePost}
              deletePostAndBanUser={deletePostAndBanUser}
            />
          )}
        </div>
        <div className="flex flex-row items-center justify-between gap-2">
          <div className="w-full">
            <h2 className="community-post-title pb-[15px] text-[22px]">{post.title}</h2>
            <TextWithMentions
              className={twMerge(
                'preview-image-wrapper cursor-pointer overflow-hidden text-ellipsis break-all text-sm text-darkblue/90 lg:text-base',
              )}
              truncateLength={
                excessivePostTitleLength > 0
                  ? MAX_CONTENT_LENGTH_MOBILE - excessivePostTitleLength
                  : MAX_CONTENT_LENGTH_MOBILE
              }
              text={post.content}
              mentions={post.mentions}
            />
            <button className="pt-[15px] leading-none text-blue hover:underline">
              {t('post_announce.post_show_more')}
            </button>
            {!!primaryAttachment && (
              <AttachmentPreviewItem
                className="visible min-w-fit md:hidden"
                attachment={primaryAttachment}
              />
            )}
            {post.status === PostStatusEnum.Approved && (
              <div className="mt-[7px] flex items-center gap-[2px]">
                {handleLikeClick && (
                  <div onClick={handleWithoutPropagating()}>
                    <LikeButton
                      isLiked={post.isLiked}
                      likesCount={post.likesCount}
                      handleLikeClick={handleLikeClick}
                      postId={post.id}
                    />
                  </div>
                )}
                <PostCommentsInfo info={post.postCommentsInfo} />
              </div>
            )}
          </div>
          {!!primaryAttachment && (
            <AttachmentPreviewItem
              className="min-w-fit max-md:hidden"
              attachment={primaryAttachment}
            />
          )}
        </div>
      </div>
      <PostModal
        deletePost={deletePost}
        deletePostAndBanUser={deletePostAndBanUser}
        opened={opened}
        onClose={handleModalClose}
        post={post}
        handleLikeClick={handleLikeClick}
        dashboardLocale={dashboardLocale}
        topicPath={topicPath}
        commentsCount={commentsCount}
      />
    </PostAttachmentsProvider>
  )
}

export function PostAnnounceSkeleton() {
  return (
    <div className="mb-10 animate-pulse overflow-hidden rounded-lg bg-white">
      <div className="w-full px-5 py-5 lg:px-16 lg:py-10">
        <div className="mb-4 flex items-center gap-2">
          <div className="h-6 w-6 rounded-full bg-gray" />
          <div className="h-4 w-14 rounded-sm bg-gray" />
          <div className="h-4 w-14 rounded-sm bg-gray" />
        </div>
        <div className="mb-2 h-7 rounded-sm bg-gray" />
        <div className="mb-5 h-7 w-3/4 rounded-sm bg-gray" />
        {Array.from(Array(6).keys()).map(index => (
          <div className="mb-3 h-3 rounded-sm bg-gray" key={index} />
        ))}
      </div>
      <hr className="mx-5 px-5 text-gray lg:mx-0" />
      <div className="flex w-full justify-between px-5 py-5 lg:px-16 lg:py-10">
        <div className="flex gap-4">
          <div className="flex items-center gap-2">
            <div className="h-6 w-6 rounded-full bg-gray" />
            <div className="h-4 w-14 rounded-sm bg-gray" />
          </div>
          <div className="flex items-center gap-2">
            <div className="h-6 w-6 rounded-full bg-gray" />
            <div className="h-4 w-14 rounded-sm bg-gray" />
          </div>
        </div>
        <div className="">
          <div className="h-6 w-20 rounded-sm bg-gray" />
        </div>
      </div>
    </div>
  )
}

export default PostAnnounce
